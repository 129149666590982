import { Controller } from "@hotwired/stimulus";

const DEFAULT_ADDITIONAL_ITEM_ID = "ADDITIONAL_ITEM_ID";

export default class extends Controller {
  static targets = [
    "additionalItemWrapper",
    "additionalItemTemplate",
    "additionalItemIndex",
  ];

  static values = {
    index: String,
  };

  connect() {
    this.resetIndex();
  }

  addAdditionalFormItem(event) {
    event.preventDefault();
    // index に、置換対象の index 文字列を指定することが出来る。
    // nest させるときは子のテンプレートまで置換されてしまうので、指定すること。
    const additionalItemIndex = this.indexValue || DEFAULT_ADDITIONAL_ITEM_ID;
    const content = this.additionalItemTemplateTarget.innerHTML.replace(
      new RegExp(additionalItemIndex, "g"),
      Date.now().valueOf(),
    );

    this.additionalItemWrapperTarget.insertAdjacentHTML("beforeend", content);
    this.resetIndex();
  }

  removeAdditionalFormItem(event) {
    event.preventDefault();
    const item = event.target.closest(".additional-form-item");
    item.remove();
    this.resetIndex();
  }

  destroyAdditionalFormItem(event) {
    event.preventDefault();
    const item = event.target.closest(".additional-form-item");
    item.querySelector("input[name*='[_destroy]']").value = "1";
    item.style.display = "none";
    this.resetIndex();
  }

  resetIndex() {
    let index = 0;
    this.additionalItemIndexTargets.forEach((indexItem) => {
      const item = indexItem.closest(".additional-form-item");
      if (item.style.display == "none") {
        return;
      }
      indexItem.textContent = index + 1;
      index++;
    });
  }
}
