import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["check", "indicator"];

  toggleSelection() {
    this.checkTarget.checked = !this.checkTarget.checked;

    if (this.checkTarget.checked) {
      this.indicatorTarget.style.display = null;
    } else {
      this.indicatorTarget.style.display = "none";
    }
  }
}
