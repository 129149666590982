import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  emit(event) {
    if (event.target.checked) {
      this.dispatch("check", { target: event.target });
    } else {
      this.dispatch("uncheck", { target: event.target });
    }
  }
}
