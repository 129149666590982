import { Controller } from "@hotwired/stimulus";

// 担当者割当画面のタスク割当状況を動的に更新する

export default class extends Controller {
  static targets = [
    "input",
    "currentCount", // 割当済件数
    "additionalCount", // 新規割当件数
    "totalCount", // 割当後件数
    "currentGraph", // 割当済グラフ
    "additionalGraph", // 新規割当グラフ
    "blankInputsCount", // 未選択の担当者input数
  ];
  static values = { currentAssignments: Object };

  connect() {
    this.updateStats();
  }

  getAssigneeId(element) {
    return element.dataset["assigneeId"];
  }

  getWorkflowId(element) {
    return element.dataset["workflowId"];
  }

  /**
   * 割当済のstatsを計算するreducer
   */
  currentStatsReducer = (stats, [workflowId, assigneeId]) => {
    const isCurrentAssignment =
      !this.inputTargets
        .map((inputTarget) => this.getWorkflowId(inputTarget))
        .includes(workflowId) ||
      this.inputTargets.find(
        (inputTarget) => this.getWorkflowId(inputTarget) == workflowId,
      )?.value == assigneeId;

    if (isCurrentAssignment) {
      stats[assigneeId]++;
    }
    return stats;
  };

  /**
   * 新規割当のstatsを計算するreducer
   */
  additionalStatsReducer = (stats, inputTarget) => {
    const isAdditionalAssignment =
      inputTarget.value !=
      this.currentAssignmentsValue[this.getWorkflowId(inputTarget)];

    if (isAdditionalAssignment) {
      stats[inputTarget.value]++;
    }
    return stats;
  };

  defaultStats = () =>
    this.totalCountTargets.reduce(
      (stats, totalCountTarget) => (
        (stats[this.getAssigneeId(totalCountTarget)] = 0), stats
      ),
      {},
    );

  blankInputsCount = () => {
    return this.inputTargets.filter((inputTarget) => inputTarget.value == "")
      .length;
  };

  updateStats() {
    const currentStats = Object.entries(this.currentAssignmentsValue).reduce(
      this.currentStatsReducer,
      this.defaultStats(),
    );

    const additionalStats = this.inputTargets.reduce(
      this.additionalStatsReducer,
      this.defaultStats(),
    );

    this.updateCounts(currentStats, additionalStats);
    this.updateGraphs(currentStats, additionalStats);
  }

  updateCounts(currentStats, additionalStats) {
    this.currentCountTargets.forEach((target) => {
      const assigneeId = this.getAssigneeId(target);
      target.textContent = currentStats[assigneeId];
    });

    this.additionalCountTargets.forEach((target) => {
      const assigneeId = this.getAssigneeId(target);
      target.textContent = additionalStats[assigneeId];
    });

    this.totalCountTargets.forEach((target) => {
      const assigneeId = this.getAssigneeId(target);
      target.textContent =
        currentStats[assigneeId] + additionalStats[assigneeId];
    });

    this.blankInputsCountTargets.forEach(
      (target) => (target.textContent = this.blankInputsCount()),
    );
  }

  updateGraphs(currentStats, additionalStats) {
    const maxTotalCount = Math.max(
      ...this.totalCountTargets.map((totalCountTarget) =>
        Number(totalCountTarget.textContent),
      ),
    );

    this.currentGraphTargets.forEach((target) => {
      const assigneeId = this.getAssigneeId(target);
      target.style.width = maxTotalCount
        ? `${(currentStats[assigneeId] * 100) / maxTotalCount}%`
        : 0;
    });

    this.additionalGraphTargets.forEach((target) => {
      const assigneeId = this.getAssigneeId(target);
      target.style.width = maxTotalCount
        ? `${(additionalStats[assigneeId] * 100) / maxTotalCount}%`
        : 0;
    });
  }
}
