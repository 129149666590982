import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["target"];
  static values = { number: Number };

  numberValueChanged() {
    const formatter = new Intl.NumberFormat("ja-JP", {
      style: "currency",
      currency: "JPY",
      currencyDisplay: "name",
    });

    this.targetTarget.textContent = formatter.format(this.numberValue);
  }
}
