document.addEventListener("turbo:frame-missing", (event) => {
  const {
    detail: { response, visit },
  } = event;

  // 500エラーでもframe missingになるがjsのエラーではないのでsentryに通知しない。
  if (response.status >= 500) {
    event.preventDefault();
    return;
  }

  if (!response.redirected) {
    // ここで処理しなくともsentryにはframe missingが補足される。
    return;
  }

  // sessionが切れていた場合はredirectされるので。この処理がないとcontent
  // missingが表示されるだけになる。
  event.preventDefault();
  event.detail.visit(event.detail.response);
});
