import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggle"];
  static classes = ["toggle"];

  toggle() {
    this.toggleTargets.forEach((t) => t.classList.toggle(this.toggleClass));
  }
}
