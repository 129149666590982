import "@hotwired/turbo-rails";
import { clearCache, visit } from "@hotwired/turbo";
import * as ActiveStorage from "@rails/activestorage";
import "trix";
import "@rails/actiontext";
import "../../vendor/assets/javascripts/@fontawesome/fontawesome-pro/all.min.js";

ActiveStorage.start();

import "./controllers";
import "./turbo_frame_missing_handler";

// NOTE: FontAwesomeがページ読み込み時にチラつく問題への対応
// https://fontawesome.com/v5.15/how-to-use/on-the-web/using-with/turbolinks
FontAwesome.config.mutateApproach = "sync";
