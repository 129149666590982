import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

// Choices.jsとstimulusの糊付け。
//
// https://github.com/Choices-js/Choices
export default class extends Controller {
  static targets = ["select"];

  connect() {
    new Choices(this.selectTarget, {
      // この辺のoptionsは外から受け取る様にする。
      shouldSort: false,
      classNames: {
        containerOuter: ["choices", "w-full"],
      },
      placeholder: false,
      callbackOnCreateTemplates: () => ({
        // NOTE: choicesjsはselect要素を別のdivでwrapして擬似的にフォームの見た目にしているので
        // validation errorを可視化するには中のselectのvalidityをwrapしているdivに
        // 教える必要がある。
        containerInner: (...args) => {
          const div = Choices.defaults.templates.containerInner.call(
            this,
            ...args,
          );
          const ariaInvalid = this.selectTarget.getAttribute("aria-invalid");
          div.setAttribute("aria-invalid", ariaInvalid);
          return div;
        },
      }),
    });
  }
}
