import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggler", "togglees"];
  static values = { show: Boolean, states: Object };

  connect() {
    this.setDisplays();
    this.setDisplaysByStates();
  }

  toggleShow() {
    this.showValue = !this.showValue;
    this.setDisplays();
  }

  show() {
    this.showValue = true;
    this.setDisplays();
  }

  hide() {
    this.showValue = false;
    this.setDisplays();
  }

  setDisplays() {
    const shown = this.showValue;
    this.toggleesTargets.forEach(function (elem) {
      if (shown) {
        elem.style.display = null;
      } else {
        elem.style.display = "none";
      }
    });
  }

  setDisplaysByStates(event) {
    if (event?.params.states) {
      this.statesValue = event.params.states;
    }
    for (let id in this.statesValue) {
      this.toggleesTargets
        .filter((item) => item.id == id)
        .forEach((elem) => {
          elem.style.display = this.statesValue[id] ? null : "none";
        });
    }
  }
}
