import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "additionalItemTable",
    "additionalItemTemplate",
    "payoutCalculationButton",
  ];

  addAdditionalLineItem(event) {
    event.preventDefault();
    const content = this.additionalItemTemplateTarget.innerHTML.replace(
      /TEMPLATE_RECORD_ID/g,
      Date.now().valueOf(),
    );
    this.additionalItemTableTarget.insertAdjacentHTML("beforeend", content);
  }

  removeAdditionalLineItem(event) {
    event.preventDefault();
    const item = event.target.closest(".additional-line-item-row");
    item.querySelector(".destroy > input[type='hidden']").value = "1";
    item.hidden = true;
    this.calculate();
  }

  calculate() {
    this.payoutCalculationButtonTarget.click();
  }
}
