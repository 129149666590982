import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    original: String,
    toggle: String,
  };

  static targets = ["input"];

  toggle(event) {
    if (event.target.checked) {
      this.inputTarget.value = this.originalValue;
    } else {
      this.inputTarget.value = this.toggleValue;
    }

    // NOTE: jsからinputのvalueを変更してもeventは発火しないらしい。
    this.inputTarget.dispatchEvent(new Event("change"));
  }
}
