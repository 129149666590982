import { Controller } from "@hotwired/stimulus";

// formタグで生成したフォーム内の値を操作するコントローラー。
export default class extends Controller {
  static targets = ["form", "select", "input"];

  // formタグで生成したフォーム内の値をpayloadにセットして、extractedイベントを投げる。
  extract(event) {
    // バリデーションエラーを表示するために、preventDefaultよりも前にvalidationする。
    if (!this.formTarget.checkValidity()) return;

    event.preventDefault();

    const formValues = Object.fromEntries(
      this.inputValues().concat(this.selectValues()),
    );

    this.dispatch("extracted", { detail: formValues });
  }

  inputValues() {
    return this.inputTargets.map((e) => [e.name, e.value]);
  }

  selectValues() {
    return this.selectTargets.map((e) => [
      e.name,
      e.options[e.selectedIndex].value,
    ]);
  }
}
