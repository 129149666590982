import { Controller } from "@hotwired/stimulus";

// NOTE: data-turbo-confirmを使うこと。ただしそれはformにしか付けられないので、
// 特定の場合にこれが必要なことがある。
//
// 確認ダイアログを表示する。
export default class extends Controller {
  static values = { message: String };

  confirm(event) {
    if (!window.confirm(this.messageValue)) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }
}
