import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  enable() {
    this.inputTarget.removeAttribute("disabled");
  }

  disable() {
    this.inputTarget.setAttribute("disabled", true);
  }
}
