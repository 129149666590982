import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["add"];
  static classes = ["add"];

  add() {
    this.addTargets.forEach((t) => {
      if (!t.classList.contains(this.addClass)) {
        t.classList.add(this.addClass);
      }
    });
  }
}
