import { Controller } from "@hotwired/stimulus";

const activeClasses = [
  "active-master-item",
  "odd:bg-primary-50",
  "even:bg-primary-50",
  "!border-primary-400",
];

export default class extends Controller {
  static values = {
    detailHref: String,
    detailTurboFrameId: String,
  };

  connect() {
    // NOTE: 初めは先頭のitemをactiveにしておく
    const masterItems = document.querySelectorAll(`.master-item`);
    if (!masterItems.length) {
      return;
    }
    masterItems[0].classList.add(...activeClasses);
  }

  highlightCurrent(event) {
    const masterItems = document.querySelectorAll(`.master-item`);
    if (!masterItems.length) {
      return;
    }

    masterItems.forEach((elem) => {
      if (elem.contains(event.target)) {
        elem.classList.add(...activeClasses);
      } else {
        elem.classList.remove(...activeClasses);
      }
    });
  }

  showDetail() {
    const detailHref = this.detailHrefValue;
    const detailTurboFrameId = this.detailTurboFrameIdValue;
    if (!(detailHref && detailTurboFrameId)) {
      return;
    }

    const detailTurboFrameElement = document.querySelector(
      `turbo-frame#${detailTurboFrameId}`,
    );
    if (!detailTurboFrameElement) {
      return;
    }

    detailTurboFrameElement.src = detailHref;
  }
}
