import { Controller } from "@hotwired/stimulus";
import { clearCache, visit } from "@hotwired/turbo";

export default class extends Controller {
  connect() {
    this.close();
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.close();
    }
  }

  open(e) {
    this.element.style.display = null;
  }

  close() {
    this.element.style.display = "none";
  }
}
