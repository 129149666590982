import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["anchor"];

  encode({ target: { name, value } }) {
    for (const anchor of this.anchorTargets) {
      const params = new URLSearchParams(anchor.search);
      params.set(name, value);
      anchor.search = params;
    }
  }
}
