import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "targets"];

  setDisabled() {
    const checkbox = this.checkboxTarget;
    this.targetsTargets.forEach(function (elem) {
      elem.disabled = !checkbox.checked;
    });
  }
}
