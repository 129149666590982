import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String };

  static targets = ["token", "maskedNumber"];

  createToken({
    detail: {
      card_number,
      expiration_month,
      expiration_year,
      security_code,
      month_of_birth,
      day_of_birth,
      last_name_katakana,
      first_name_katakana,
    },
  }) {
    // > API にカード番号,カード有効期限(年),カード有効期限(月),セキュリティコード, 生月日(月),生月日(日),
    // > 電話番号,カナ氏名(姓),カナ氏名(名)の順番でセットする。(設定しない項目は空文字をセット)
    // NOTE: 生月日は0 paddingしないと動かない。
    SpsvApi.spsvCreateToken(
      card_number,
      expiration_year,
      expiration_month,
      security_code,
      month_of_birth.padStart(2, "0"),
      day_of_birth.padStart(2, "0"),
      "",
      last_name_katakana,
      first_name_katakana,
    );
  }

  setToken({ detail: { token, maskedNumber } }) {
    this.tokenTarget.value = token;
  }

  setMaskedNumber({ detail: { token, maskedNumber } }) {
    this.maskedNumberTarget.value = maskedNumber;
  }

  connect() {
    document.body.appendChild(this.buildEScottScriptTag());
    document.body.appendChild(this.buildCallbackScriptTag());
  }

  buildEScottScriptTag() {
    const script = document.createElement("script");
    script.src = this.urlValue;
    script.id = escottScriptTagId;
    script.className = "spsvToken";
    script.setAttribute("callbackfunc", "onTokenized");
    return script;
  }

  buildCallbackScriptTag() {
    const script = document.createElement("script");
    const content = document.createTextNode(this.buildCallbackFunc());
    script.appendChild(content);
    return script;
  }

  buildCallbackFunc() {
    return `function onTokenized(token, maskedNumber) {
      const event = new CustomEvent('e-scott:tokenized', {
        detail: { token: token, maskedNumber: maskedNumber },
      });
      document.dispatchEvent(event);
    }`;
  }
}

const escottScriptTagId = "e-scott-script";
const callbackScriptTagId = "e-scott-callback";
