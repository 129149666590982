import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { shopId: String };
  static targets = ["token", "error"];

  createToken({
    detail: { holdername, cardno, expire_month, expire_year, securitycode },
  }) {
    Multipayment.init(this.shopIdValue);

    const expire = "20" + expire_year + expire_month;
    // tokennumber は作るトークンの数で、1 つで十分なので 1 に固定する。
    // See: https://static.mul-pay.jp/doc/card-token/
    const params = {
      holdername,
      cardno,
      expire,
      securitycode,
      tokennumber: 1,
    };

    Multipayment.getToken(params, (result) => {
      if (result.resultCode == "000") {
        this.#processSuccess(result);
      } else {
        this.#processFailed(result);
      }
    });
  }

  #processSuccess(result) {
    this.#setToken(result.tokenObject.token[0]);
    const event = new CustomEvent("gmo-credit-card:success", {
      detail: { result },
    });
    document.dispatchEvent(event);
  }

  #processFailed(result) {
    this.#setErrorMessage(
      `カード情報の登録に失敗しました: ${result.resultCode}`,
    );
    const event = new CustomEvent("gmo-credit-card:failed", {
      detail: { result },
    });
    document.dispatchEvent(event);
  }

  #setToken(token) {
    this.tokenTarget.value = token;
  }

  #setErrorMessage(message) {
    this.errorTarget.innerText = message;
  }
}
