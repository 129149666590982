import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  inputTargetConnected() {
    this.inputTargets.forEach((input, index) => {
      input.value = index;
    });
  }
}
