import { Controller } from "@hotwired/stimulus";

// formをターゲットに設定して，submitするためだけのController
export default class extends Controller {
  static targets = ["form"];

  submit(event) {
    this.formTarget.requestSubmit();
  }
}
