import { Controller } from "@hotwired/stimulus";
import { Decimal, DecimalError } from "decimal.js";

export default class extends Controller {
  static targets = ["unitPrice", "quantity", "subtotal", "subtotalText"];

  connect() {
    this.calculate();
  }

  calculate() {
    // 実装はmonorailから輸入。年単位で安定稼働してるのでバグってはないと思う。
    // https://github.com/anipos/monorail/blob/c170baf37a7fe1766129f0a5ea01f4acf074af6f/app/javascript/controllers/multiply_numbers_controller.js
    const unitPrice = this.#newDecimalOrZero(this.unitPriceTarget.value);
    const quantity = this.#newDecimalOrZero(this.quantityTarget.value);
    // 切り捨ての領収書が多いらしいので四捨五入ではなく切り捨てる。
    // See https://github.com/anipos/zoo/issues/3902.
    const subtotal = unitPrice.mul(quantity).floor().toString();

    this.subtotalTarget.value = subtotal;
    this.subtotalTextTarget.dataset.numberToCurrencyNumberValue = subtotal;
  }

  resetToZero() {
    this.subtotalTarget.value = 0;
  }

  #newDecimalOrZero(string) {
    try {
      return new Decimal(string);
    } catch (DecimalError) {
      return new Decimal(0);
    }
  }
}
