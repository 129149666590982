import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["remove"];
  static classes = ["remove"];

  remove() {
    this.removeTargets.forEach((t) => {
      t.classList.remove(this.removeClass);
    });
  }
}
