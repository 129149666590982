import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item", "total", "table", "template"];

  connect() {
    this.calculate();
  }

  calculate() {
    const total = this.itemTargets.reduce((total, element) => {
      const value = parseInt(element.value);
      if (!isNaN(value)) {
        total += value;
      }
      return total;
    }, 0);

    this.totalTarget.dataset.numberToCurrencyNumberValue = total;
  }

  addItem(event) {
    event.preventDefault();
    const content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD_ID/g,
      Date.now().valueOf(),
    );
    this.tableTarget
      .querySelector("tbody")
      .insertAdjacentHTML("beforeend", content);
  }

  removeItem(event) {
    event.preventDefault();
    const item = event.target.closest(".nested-fields");
    item.remove();
    this.calculate();
  }
}
