import { Controller } from "@hotwired/stimulus";

// Tagにhrefを設定する事で任意のtagをclickでhrefに遷移可能にする。
export default class extends Controller {
  visit(event) {
    event.preventDefault();
    const href = event.currentTarget.dataset["href"];
    const isTargetBlank = event.ctrlKey || event.metaKey;
    if (isTargetBlank) {
      window.open(href, "_blank");
    } else {
      Turbo.visit(href);
    }
  }
}
